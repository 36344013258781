import "core-js/modules/es.object.to-string";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);
var routes = [{
  path: "/",
  name: "Home",
  component: Home
}, {
  path: "/about",
  name: "About",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    "../views/About.vue");
  }
}, {
  path: "/s/:songID/:mode?",
  name: "Song",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/Song.vue");
  }
}, {
  path: "/p",
  name: "Playlists",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/Playlists.vue");
  }
}, {
  path: "/pa",
  name: "PlaylistsArchived",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/PlaylistsArchived.vue");
  }
}, {
  path: "/p/:playlistID/:mode?",
  name: "Playlist",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/Playlist.vue");
  }
}, {
  path: "/p/:playlistID/s/:songID/:mode?",
  name: "PlaylistSong",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/PlaylistSong.vue");
  }
}, {
  path: "/p/:playlistID/i/:itemID/select",
  name: "PlaylistSongSelect",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/PlaylistSongSelect.vue");
  }
}, {
  path: "/projection",
  name: "Projection",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "presentation" */
    "../views/Projection.vue");
  }
}];
var router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});
export default router;