import { Subject } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { tap, map, filter, exhaustMap } from "rxjs/operators";
import { cookie$, readCookieValue } from "./cookies";
import { b64Decode } from "@/lib/b64";
var authDataCookie = "a11n.d";

var expired = function expired(v) {
  return v.exp >= Math.floor(Date.now() / 1000);
};

export var creds$ = new Subject();

var login = function login(username, password) {
  creds$.next({
    username: username || "hubert.lobit",
    password: password || "password"
  });
};

export var auth$ = creds$.pipe(exhaustMap(function (creds) {
  return fromFetch("/api/v1/auth", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(creds)
  });
}), // NOTE: this done to monitor cookie data changes
tap(function () {
  document.cookie = document.cookie + "";
}));
export var authData$ = cookie$.pipe(map(function (cookie) {
  return readCookieValue(cookie, authDataCookie);
}), tap(function (v) {
  if (v) return;
  console.log("no token");
  login();
}), filter(function (v) {
  return !!v;
}), map(function (v) {
  return JSON.parse(b64Decode(v));
}), tap(function (v) {
  if (expired(v)) return;
  console.log("expired");
  login();
}), filter(function (v) {
  return expired(v);
}));