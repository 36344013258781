import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-prototype-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import { BehaviorSubject } from "rxjs";
export var cookie$ = new BehaviorSubject(document.cookie);
export var readCookieValue = function readCookieValue(cookie, key) {
  var b = cookie.match("(^|[^;]+)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
};

(function setCookieObservable() {
  var key = "cookie";
  var target = Object.getPrototypeOf(Object.getPrototypeOf(document));
  var descriptor = Object.getOwnPropertyDescriptor(target, key);
  if (!descriptor) return;
  var setter = descriptor.set;
  if (!setter) return;
  Object.defineProperty(target, key, {
    set: function set(value) {
      setter.call(document, value);
      cookie$.next(document.cookie);
    }
  });
})();