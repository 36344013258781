import { BehaviorSubject } from "rxjs";
export var offlineMode$ = new BehaviorSubject(false);
export var wakeLockEnabled$ = new BehaviorSubject(true); //window.addEventListener("load", () => {
//  console.log("load");
//  window.addEventListener("online", () => {
//    // 🦄🎊🔥 we're back online!
//    console.log("online");
//    online$.next(true);
//  });
//
//  window.addEventListener("offline", () => {
//    // 👨💻🙅😱 oh no!
//    console.log("offline");
//    online$.next(false);
//  });
//});