import "/app/node_modules/core-js/modules/es.array.iterator.js";
import "/app/node_modules/core-js/modules/es.promise.js";
import "/app/node_modules/core-js/modules/es.object.assign.js";
import "/app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
Vue.config.productionTip = false; // Register a global custom directive called `v-focus`

Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function inserted(el) {
    // Focus the element
    el.focus();
  }
});
new Vue({
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");
import { ReactInVue } from "vuera"; // This one has to initialize its routing mode
// It is very slow but avoid a bug that we hope will be fixed in spectacle 6

import SongPresentationReact from "./components/SongPresentation.react";
export var SongPresentation = ReactInVue(SongPresentationReact);