import { BehaviorSubject, combineLatest, of } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { catchError, flatMap, switchMap, shareReplay, startWith } from "rxjs/operators";
import { authData$ } from "./a11n";
import { filter$ } from "./search";
export var songs$ = combineLatest(filter$, authData$, function (filter) {
  return filter;
}).pipe(switchMap(function (filter) {
  return fromFetch("/api/v2/song-versions?sort=title|asc&filter=".concat(filter), {
    headers: {
      Accept: "application/json"
    }
  });
}), flatMap(function (res) {
  return res.json();
}), startWith({
  data: []
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));
export var songID$ = new BehaviorSubject(null);
export var songMode$ = new BehaviorSubject("chords");
export var song$ = combineLatest(songID$, authData$, function (id) {
  return id;
}).pipe(switchMap(function (id) {
  return id ? fromFetch("/api/v2/song-versions/".concat(id), {
    headers: {
      Accept: "application/json"
    }
  }) : of(null);
}), flatMap(function (res) {
  return res ? res.json() : of(null);
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));
export var teamSong$ = combineLatest(songID$, authData$, function (id) {
  return id;
}).pipe(switchMap(function (id) {
  return id ? // Hardcoded team
  fromFetch("/api/v2/teams/1/song-versions/".concat(id), {
    headers: {
      Accept: "application/json"
    }
  }).pipe(switchMap(function (res) {
    if (res.status === 404) {
      return of(null);
    }

    if (!res.ok) {
      throw "There was an error with status ".concat(res.status);
    }

    return of(res);
  }), catchError(function (err) {
    console.error(err);
    return of(null);
  })) : of(null);
}), flatMap(function (res) {
  return res ? res.json() : of(null);
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));