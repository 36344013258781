import Vue from "vue";
import VueRx from "vue-rx";
export * from "./a11n";
export * from "./cookies";
export * from "./settings";
export * from "./songs";
export * from "./wake-lock";
export * from "./search";
export * from "./playlists";
export * from "./menu";
Vue.use(VueRx);