import { Observable, BehaviorSubject, combineLatest, of } from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { flatMap, switchMap, shareReplay, startWith } from "rxjs/operators";
import { authData$ } from "./a11n";
import { filter$ } from "./search";
export var playlists$ = combineLatest(filter$, authData$, function (filter) {
  return filter;
}).pipe(switchMap(function (filter) {
  return fromFetch("/api/v2/playlists?sort=name|asc&filter=".concat(filter), {
    headers: {
      Accept: "application/json"
    }
  });
}), flatMap(function (res) {
  return res.json();
}), startWith({
  data: []
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));
export var playlistID$ = new BehaviorSubject(null);
export var playlistItemID$ = new BehaviorSubject(null);
export var playlistMode$ = new BehaviorSubject("list");
export var playlistUpdates$ = playlistID$.pipe(switchMap(function (id) {
  return id ? Observable.create(function (observer) {
    var eventSource = new EventSource("/sse/".concat(id));
    eventSource.addEventListener("message", function (event) {
      return observer.next(event.data);
    });
    eventSource.addEventListener("error", function (event) {
      return observer.error(event);
    });
    return function () {
      eventSource.close();
    };
  }) : of(null);
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));
export var playlist$ = combineLatest(playlistID$, authData$, playlistUpdates$.pipe(startWith(null)), function (id) {
  return id;
}).pipe(switchMap(function (id) {
  return id ? fromFetch("/api/v2/playlists/".concat(id), {
    headers: {
      Accept: "application/json"
    }
  }) : of(null);
}), flatMap(function (res) {
  return res ? res.json() : of(null);
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));
export var playlistItems$ = combineLatest(playlistID$, authData$, playlistUpdates$.pipe(startWith(null)), function (id) {
  return id;
}).pipe(switchMap(function (id) {
  return id ? fromFetch("/api/v2/playlists/".concat(id, "/items?per_page=1000"), {
    headers: {
      Accept: "application/json"
    }
  }) : of(null);
}), flatMap(function (res) {
  return res ? res.json() : of(null);
}), shareReplay({
  bufferSize: 1,
  refCount: true
}));